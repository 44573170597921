<div class="container-fluid footer-bg">
    <div class="row">
        <div class="col-sm-12 col-md-6" style="padding-left:30px;">
        <ul class="col-sm-12 list-style d-flex align-items-center" style="padding-left: 0px;">
                <li>Cookie Policy&nbsp;&nbsp;&nbsp;&#124;</li>
                <li>&nbsp;&nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;&nbsp;&#124;</li>
                <li>&nbsp;&nbsp;&nbsp;Terms and Conditions</li>
        </ul>
        <div>&copy;2024&#8208; All rights reserved</div>
    </div>
        <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center">
            <div>
        <ul class="col-sm-12 list-style d-flex align-items-center"style="padding-left: 0px;">
                <li>Connect Us</li>
        </ul>
        <div><a href="https://www.linkedin.com/company/parezagroup/" target="_blank"><i class="bi bi-linkedin" style="color:rgba(15, 104, 178, 1); font-size: 25px;"></i></a></div>
    </div>
    </div>
    </div>
</div>