<div *ngIf="isLoading" style="height:90vh;" class="d-flex justify-content-center align-items-center">
    
  <app-loader></app-loader>
</div>

<div class="container"style="min-height:90vh;" *ngIf="!isLoading">
   
    <div class="row mt-2 p-3 row-gap-3 gy-3 ">
      <div class="col-sm-12 col-md-12 d-flex flex-column  icons-section">
        <div style="padding-left: 30px;">
          <!-- <h2>Quick Access</h2> -->
          <h2 style="font-size: 18px; font-weight: 400;">Quick Access</h2>
          <hr>
        </div>
        <div class="row">
        <div *ngFor="let item of quickAccessWithIcons" class="col-sm-12 col-md-2 col-lg-1_5">
          <div class="feature d-flex flex-column align-items-center" (click)="goToRespectivePage(item.text)">
            <div class="icon-bg">
              <mat-icon aria-hidden="false" fontIcon="{{ item.icon }}"></mat-icon>
            </div>
            <div class="content">
              <h5 style="font-size: 13px;">{{ item.text }}</h5>
            </div>
          </div>
        </div>
      </div>
      </div>
      
     

    </div>
    <div class="row mt-1">
<div class="col-sm-12 col-md-6 p-3">
  <div class="p-2 graph-card">
    <div class="d-flex justify-content-between">
      <a routerLink="/banking" routerLinkActive="active" style="font-size:18px;text-decoration: none;cursor: pointer;" class="col-sm-7">Debit Account</a>
      <h4 class="col-sm-5 d-flex justify-content-end" [ngClass]="getBalanceClass(debitAccBalance)">{{debitAccBalance}} $</h4>
    </div>
    
  <div id="chart" ></div>
</div>
</div>
<div class="col-sm-12 col-md-6 p-3">
  <div class="p-2 graph-card">
    <div class="d-flex justify-content-between">
      <a routerLink="/banking/organization" routerLinkActive="active" style="font-size:18px;text-decoration: none;cursor: pointer;" class="col-sm-7">Organization Account</a>
      <h4 class="col-sm-5 d-flex justify-content-end" [ngClass]="getBalanceClass(organizationAccBalance)">{{organizationAccBalance}} $</h4>
    </div>
  <div id="chart1" ></div>
</div>
</div>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-sm-12 col-md-6">
<div class="add-section">
  <!-- <h2>Upcoming Events</h2> -->
  <h2 style="font-size: 18px; font-weight: 400;">Upcoming Events</h2>
  <hr>
  <div [innerHTML]="htmlContent | safeHtml"></div>
</div>
      </div>
    </div>

    
</div>
