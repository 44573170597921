<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="dashboard">Dashboard</a>
      <a mat-list-item routerLink="banking">Banking</a>
      <a mat-list-item routerLink="users">Users</a>
      <a mat-list-item class="nav-link-inserted" title="Help Icon" (click)="logout();drawer.toggle()" >
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="logout"></mat-icon>
      </a>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="background-color: #eff2ff6e;">
    <mat-toolbar color="primary" *ngIf="isShow">
      <button type="button" style="color: black;" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <a class="nav-link">
        <img class="img-pareza" src="./../../assets/images/Logo-New-High.png" alt="Cyclos">
        <!-- <span class="title">Cyclos</span> -->
      </a>
      <span class="spacer"></span>


      <div class="nav-link" *ngIf="!(isHandset$ | async)">
        <a routerLink="dashboard" routerLinkActive="active" class="nav-link-inserted">Dashboard</a>
        <a routerLink="banking" routerLinkActive="active" class="nav-link-inserted">Banking</a>
        <a routerLink="users" routerLinkActive="active" class="nav-link-inserted">Users</a>
        <a routerLink="profile" routerLinkActive="active" class="nav-link-inserted">Profile</a>
        <a routerLink="mail" routerLinkActive="active" class="nav-link-inserted">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="mail"matTooltip="Messages"></mat-icon>
        </a>
        <a routerLink="notification" routerLinkActive="active" class="nav-link-inserted">
          <mat-icon aria-hidden="false" aria-label="Example home icon" matBadge="15" matBadgeColor="warn" fontIcon="notifications" matBadgeSize="small"matTooltip="Notifications"></mat-icon>
        </a>
        <a routerLink="help" routerLinkActive="active" class="nav-link-inserted">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="help"matTooltip="Help"></mat-icon>
        </a>

        <!-- <button mat-flat-button color="success" (click)="logout()" class="nav-link-inserted">Logout</button> -->
        <a routerLink="logout" routerLinkActive="active" class="nav-link-inserted" (click)="logout()">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="logout" matTooltip="Logout" ></mat-icon>
        </a>
      </div>

    </mat-toolbar>
    <ng-content style="height: 90vh; display: block; background-color: #eff2ff6e;"></ng-content>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer *ngIf="isShow"></app-footer>


