import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

}



// @Component({
//   selector: 'progress-spinner-overview-example',
//   templateUrl: 'progress-spinner-overview-example.html',

// })
// export class ProgressSpinnerOverviewExample {}

