<div *ngIf="isLoading" style="height:90vh;" class="d-flex justify-content-center align-items-center">
    
    <app-loader></app-loader>
  </div>
<div class="container"*ngIf="!isLoading">
    <div class="row">
        <div class="col-sm-12">
            <div class="transfer-container">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <mat-icon aria-hidden="false" color="primary" aria-label="Example home icon" fontIcon="arrow_back"matTooltip="Back" style="cursor: pointer;margin-right: 5px;" (click)="goBackToBanking()"></mat-icon>
                        <!-- <h3 class="m-0">Transfer Details</h3> -->
                        <h2 class="m-0" style="font-size: 18px; font-weight: 400;">Transfer Details</h2>
                    </div>
                    
                    <div class="d-flex align-items-center">
                    <!-- <mat-icon aria-hidden="false" color="primary" aria-label="Example home icon" fontIcon="print"matTooltip="Print"></mat-icon> Print</div> -->
                    <!-- <button mat-button (click)="print()"><mat-icon aria-hidden="false" color="primary" aria-label="Example home icon" fontIcon="print"matTooltip="Print"></mat-icon>Print</button> -->
                </div>
                </div>
                <hr>

                <div class="col-sm-12 d-flex">
                    <div class="col-sm-6 col-md-3">
                        <h3>Amount</h3>
                    </div>
                    <div class="col-sm-6 d-flex">
                        <h3>{{amount}}</h3>
                    </div>
                </div>
                <div class="col-sm-12 d-flex">
                    <div class="col-sm-6 col-md-3">
                        <h3>Date</h3>
                    </div>
                    <div class="col-sm-6 d-flex">
                        <h3>{{date}}</h3>
                    </div>
                </div>
                <div class="col-sm-12 d-flex">
                    <div class="col-sm-6 col-md-3">
                        <h3>From</h3>
                    </div>
                    <div class="col-sm-6 d-flex">
                        <div *ngIf="fromType=='system'; else userIcon">
                        <i class="bi bi-briefcase icon"></i>
                    </div>
                        <ng-template #userIcon>
                            <i class="bi bi-person-circle icon"></i>
                          </ng-template>
                        <h3>{{fromAccount}}</h3>
                    </div>
                </div>
                <div class="col-sm-12 d-flex">
                    <div class="col-sm-6 col-md-3">
                        <h3>To</h3>
                    </div>
                    <div class="col-sm-6 d-flex">
                        <!-- <i class="bi bi-briefcase icon"></i> -->
                        <div *ngIf="toType=='system'; else userIcon">
                            <i class="bi bi-briefcase icon"></i>
                        </div>
                            <ng-template #userIcon>
                                <i class="bi bi-person-circle icon"></i>
                              </ng-template>
                        <h3>{{toAccount}}</h3>
                    </div>
                </div>
                <div class="col-sm-12 d-flex">
                    <div class="col-sm-6 col-md-3">
                        <h3>Payment Type</h3>
                    </div>
                    <div class="col-sm-6 col-md-9 d-flex">
                        <h3>{{paymentType}}</h3>
                    </div>
                </div>
                <div class="col-sm-12 d-flex" *ngIf="channel!=undefined">
                    <div class="col-sm-6 col-md-3">
                        <h3>Channel</h3>
                    </div>
                    <div class="col-sm-6 d-flex">
                        <h3>{{channel}}</h3>
                    </div>
                </div>
                <div class="col-sm-12 d-flex"*ngIf="canChargeBack=='false'">
                    <div class="col-sm-6 col-md-3">
                        <h3>Chargeback of</h3>
                    </div>
                    <div class="col-sm-6 d-flex">
                        <h3>{{chargeBackId}}</h3>
                    </div>
                </div>



            </div>
        </div>
    </div>

</div>