<div *ngIf="isLoading" style="height:90vh;" class="d-flex justify-content-center align-items-center">
    
    <app-loader></app-loader>
  </div>
  <div style="min-height:90vh;"*ngIf="!isLoading">
      <form [formGroup]="optionDetails">
          <div class="fields-card">
              <div class="col-sm-12 d-flex justify-content-between">
                <h2 style="font-size: 18px; font-weight: 400; margin-bottom: 0px;">Organization Account</h2>
                  <div class="d-flex">
                      <button *ngIf="isShowFiltersOrg" mat-button (click)="hideFiltersOrg()"><mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>Hide Filters</button>
                      <button *ngIf="!isShowFiltersOrg" mat-button (click)="showFiltersOrg()"><mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>Show Filters</button>
                      <!-- <button mat-button><mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="download"></mat-icon>Download</button> -->
                      
              <div class="dropdown">
                  <button mat-button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="download"></mat-icon>Download</button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                        <li><a class="dropdown-item"  (click)="downloadFormat('csv')">CSV</a></li>
                      </ul>
                    </div>
                  </div>
              </div><hr>
              <div *ngIf="isShowFiltersOrg" class="col-sm-12 d-flex" style="flex-wrap: wrap;">
                    <div class="col-sm-4 p-2">
                      <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                          <mat-label>Period</mat-label>
                          <mat-select formControlName="period">
                            <mat-option *ngFor="let period of preselectedPeriods" [value]="period.name">
                              {{period.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4 p-2 ">
                      <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                          <mat-label>Filter</mat-label>
                          <mat-select formControlName="filter" multiple="true">
                            <mat-option *ngFor="let filter of transferFilters" [value]="filter.name">
                              {{filter.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                  <div class="col-sm-4 p-2">
                  <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                      <mat-label>From Amount</mat-label>
                      <input matInput type="number" formControlName="fromAccount">
                    </mat-form-field>
                    </div>
                  <div class="col-sm-4 p-2">
                  <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                      <mat-label>To Amount</mat-label>
                      <input matInput type="number" formControlName="toAmount">
                    </mat-form-field>
                    </div>
                    <div class="col-sm-4 p-2">
                      <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                          <mat-label>Direction</mat-label>
                          <mat-select formControlName="direction">
                            <mat-option *ngFor="let direction of directions" [value]="direction.name">
                              {{direction.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div><div class="col-sm-4 p-2">
                      <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                          <mat-label>Groups</mat-label>
                          <mat-select formControlName="groups" multiple="true">
                            <mat-option *ngFor="let group of groups" [value]="group.name">
                              {{group.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div><div class="col-sm-4 p-2">
                      <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                          <mat-label>Channel</mat-label>
                          <mat-select formControlName="channel" multiple="true">
                            <mat-option *ngFor="let channel of channels" [value]="channel.name">
                              {{channel.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4 p-2">
                      <mat-form-field floatLabel="auto" appearance="outline" style="width:100%;">
                          <mat-label>Order By</mat-label>
                          <mat-select formControlName="orderBy">
                            <mat-option *ngFor="let order of orderBy" [value]="order.name">
                              {{order.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                  <div class="col-sm-4 p-2">
                          <!-- <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Assignee</mat-label>
                            <input type="text" matInput formControlName="user" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option *ngFor="let user of users" [value]="user.display">
                                {{user.display}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>     -->
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>User</mat-label>
                            <input type="text"
                                   placeholder="Type to search"
                                   aria-label="Number"
                                   matInput
                                   formControlName="user"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option *ngFor="let user of users" [value]="user.display">
                                {{user.display}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                 
              </div>
              <div *ngIf="isShowFiltersOrg" class="row"><hr>
              <div class="col-sm-12 col-md-6 d-flex justify-content-between">
                  <div class="col-sm-6"><h3>Balance on {{ transformDate(accountInfo.beginDate)}}</h3></div>
                  <div class="col-sm-6 d-flex justify-content-end" [ngClass]="getBalanceClass(accountInfo.balanceAtBegin)"><h3>{{accountInfo?.balanceAtBegin}} $</h3></div>
              </div>
              <div class="col-sm-12 col-md-6 d-flex">
                  <div class="col-sm-6"><h3>Balance on {{transformDate(accountInfo.endDate)}}</h3></div>
                  <div class="col-sm-6 d-flex justify-content-end" [ngClass]="getBalanceClass(accountInfo.balanceAtEnd)"><h3>{{accountInfo?.balanceAtEnd}} $</h3></div>
              </div>
              <div class="col-sm-12 col-md-6 d-flex">
                  <div class="col-sm-6"><h3>Total Received</h3></div>
                  <div class="col-sm-6 d-flex justify-content-end" [ngClass]="getBalanceClass(accountInfo.incomingsum)"><h3>{{accountInfo?.incomingsum}}$</h3></div>
              </div>
              <div class="col-sm-12 col-md-6 d-flex justify-content-between">
                  <div class="col-sm-6"><h3>Total Paid</h3></div>
                  <div class="col-sm-6 d-flex justify-content-end" [ngClass]="getBalanceClass(accountInfo.outgoingsum)"><h3>{{accountInfo?.outgoingsum}} $</h3></div>
              </div>
              <div class="col-sm-12 col-md-6 d-flex justify-content-between">
                  <div class="col-sm-3"><h3>Net Income</h3></div>
                  <div class="col-sm-9 d-flex justify-content-end" [ngClass]="getBalanceClass(accountInfo?.netInflow)"><h3>{{accountInfo?.netInflow}} $</h3></div>
              </div>
          </div>
          <div class="row"*ngIf="!isShowFiltersOrg">
              <div class="col-sm-12 col-md-6 d-flex justify-content-between">
                  <div class="col-sm-3"><h3>Balance</h3></div>
                  <div class="col-sm-9 d-flex justify-content-center" [ngClass]="getBalanceClass(accountInfo?.netInflow)"><h3>{{accountInfo?.netInflow}} $</h3></div>
              </div>
          </div>
          </div>
          
      </form>
  
  <div class="mt-4 table-section">
  <table mat-table [dataSource]="dataSource" class="demo-table ">
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="icon-column-width"></th>
        <td mat-cell *matCellDef="let element">
           <ng-container *ngIf="element.relatedAccount.kind == 'system'; else userIcon">
            <i class="bi bi-briefcase table-icon"></i>
          </ng-container>
          <ng-template #userIcon>
            <i class="bi bi-person-circle table-icon"></i>
          </ng-template>
        </td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">{{transformDate(element.date)}}</td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>From/To</th>
        <td mat-cell *matCellDef="let element">{{element.relatedAccount.kind=="system"?element.relatedAccount.type.internalName:element.relatedAccount.user.display}}</td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element" [ngClass]="getBalanceClass(element.amount)">{{element.amount}} $</td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
          mat-row
          (click)="showTransfers(row)"
          [class.demo-row-is-clicked]="selectedRow===row"
          *matRowDef="let row; columns: displayedColumns;"
          style="cursor: pointer;"
      ></tr>
  
    </table>
    
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
    
  </div>
  </div>
  