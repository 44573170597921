<div class="container" style="height: 100vh;">
<div class="row" style="height: 100%;">
    <div class="col-sm-12 d-flex justify-content-center align-items-center">
        <div class="col-sm-12 col-md-4 signin-card d-flex flex-column justify-content-center align-items-center">
            <div class="img-pareza">
            <img class="img-pareza-src" src="./../../assets/images/Logo-New-High.png" alt="Pareza" width="100%"height="100%">
        </div>
            <h2>Sign in with your Account</h2>
        <form [formGroup]="loginDetails" style="width:80%" class="d-flex flex-column">

           
            <div class="input-group">
                <span class="input-group-text" id="basic-addon1" style="background-color: white;">
                    <mat-icon aria-hidden="false" class="icon-color" aria-label="Example home icon" fontIcon="person"></mat-icon>
                </span>
                <input type="text" id="username" class="form-control" style="border-left:none;" placeholder="Username" formControlName="username" aria-label="Username" aria-describedby="basic-addon1">
              </div>
           
          
                <div class="form-group" style="margin-top: 15px; width: 100%;">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1" style="background-color: white;">
                        <mat-icon aria-hidden="false" class="icon-color" aria-label="Example home icon" fontIcon="key"></mat-icon>
                    </span>
                    <input id="password" [type]="passwordFieldType" style="border-left:none;" placeholder="Password" formControlName="password" class="form-control" />
                    <span class="input-group-text" (click)="togglePasswordVisibility()" style="cursor: pointer; background-color: white;">
                    
                      <mat-icon aria-hidden="false" class="icon-color" aria-label="Example home icon" fontIcon={{passwordFieldIcon}}></mat-icon>
                    </span>
                  </div>
                </div>
                
            <a href="" style="margin-top:10px;text-align: end;text-decoration: none;">Forgot Password</a> 
            <button class="btn btn-dark" (click)="signIn()" style="width:100%; margin-top:15px; border:none; background-color: rgba(15, 104, 178, 1);">Sign In</button>
            <!-- <button mat-button color="primary" (click)="signIn()" style="width:100%">submit</button> -->
            <div class="d-flex justify-content-center">
            <h4 class="text-line">register with us</h4>
        </div>
            <button class="btn btn-secondary" style="width:100%;background-color: #e5e3e3; color:black;">not a user yet? Register with us</button>
            <h5 style="text-align: center;margin-top: 5px;">By Clicking continue, You agree to our <span class="text-bold">Terms of service</span> and <span class="text-bold">Privacy policy</span></h5>
        </form>
        </div>

    </div>
</div>
</div>
